import React, { useEffect, useState } from "react";
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";
import ArrowLeft from "../svg/Icon-Arrow-Left.inline.svg";
import ArrowRight from "../svg/Icon-Arrow-Right.inline.svg";
import GalleryModal from "./GalleryModal";
import ZoomSvg from "../svg/Icon-Zoom.svg";

import * as style from "./Gallery.module.scss";

const query = graphql`
  {
    allFile(
      filter: { relativeDirectory: { regex: "/galleries/" } }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        relativeDirectory
        childImageSharp {
          id
          gatsbyImageData(
            layout: FIXED
            placeholder: BLURRED
            formats: [WEBP, AUTO]
          )
        }
      }
    }
  }
`;

const Gallery = ({ source, variant, stl, imgTag }) => {
  //   const imagesData = data.allFile.nodes;
  const imagesData = useStaticQuery(query).allFile.nodes.filter((ele) =>
    ele.relativeDirectory.includes(source)
  );

  // const [currImage,setCurrImage]= useState(getImage(imagesData[0]))

  const [scrollPosition, setScrollPosition] = useState(0);
  const [showFullGallery, setShowFullGallery] = useState(false);
  const [mobile, setMobile] = useState(false);

  const scroll = async (a) => {
    let dataLength = imagesData.length;
    if (lqData.length > 0) dataLength = lqData.length;
    setScrollPosition((prevState) => {
      if (prevState + a > dataLength - 1) return 0;
      if (prevState + a < 0) return dataLength - 1;
      return prevState + a;
    });
    // setCurrImage(getImage(lqData[scrollPosition]))
  };

  //populating with init data
  let lqData = imagesData; //for section gallery
  let hqData = imagesData; //for modal gallery

  //create subsets if exist and assign -> temp solution
  let imagesDataMobile = imagesData.filter((ele) =>
    ele.name.match(/thumb-mobile$/)
  );
  let imagesDataThumb = imagesData.filter((ele) => ele.name.match(/thumb$/));
  let imagesDataFullres = imagesData.filter((ele) =>
    ele.name.match(/fullres$/)
  );

  if (imagesDataFullres.length > 0) hqData = imagesDataFullres;
  if (imagesDataThumb.length > 0) lqData = imagesDataThumb;

  useEffect(() => {
    const html = document.querySelector("html");
    const cw = html.clientWidth;
    console.log({ cw });
    //image data shufle for smal sizes
    if (cw < 992 && imagesDataMobile.length > 0) {
      setMobile(true);
    }
    console.log({ lqData });
  }, []);

  // console.log(imagesData);
  // console.log(imagesDataMobile);
  // console.log(imagesDataThumb);

  return (
    <div className={style.container}>
      <div
        className={style.imgContainer}
        onClick={() => {
          // console.log("klik w galery");
          setShowFullGallery(true);
        }}
      >
        <GatsbyImage
          className={style.imgWrapper}
          image={
            mobile
              ? getImage(imagesDataMobile[scrollPosition])
              : getImage(lqData[scrollPosition])
            // currImage
            // getImage(lqData[scrollPosition])
            // lqData.length > 0
            //   ? getImage(lqData[scrollPosition])
            //   : getImage(imagesData[scrollPosition])
          }
          style={{
            // position: "absolute",
            width: "100%",
            height: "100%",
            // overflow: "hidden",
          }}
          imgStyle={{
            objectFit: "cover",
            cursor: "zoom-in",
          }}
          alt=""
        />
        <div className={style.overlay}>
          <ZoomSvg />
        </div>
        {imgTag ? (
          <div className={style.tag}>
            <span>{imgTag}</span>
          </div>
        ) : (
          ""
        )}
      </div>
      <div
        className={
          stl ? `${style.navigation} ${style.invert}` : style.navigation
        }
        style={variant == "mirror" ? { left: "auto", right: "0px" } : {}}
      >
        <div className={style.arrows}>
          <div onClick={() => scroll(-1)}>
            <ArrowLeft />
          </div>
          <div onClick={() => scroll(1)}>
            <ArrowRight />
          </div>
        </div>
      </div>

      {hqData.length > 0 ? (
        <GalleryModal
          imagesData={
            mobile ? lqData : hqData
            // hqData
            // imagesDataFullres.length > 0 ? hqData : imagesData
          }
          open={showFullGallery}
          setOpen={setShowFullGallery}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default Gallery;
