import React, { useState, useEffect } from "react";
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";
import * as style from "./HeroSection.module.scss";
import ArrowLeft from "../../svg/Icon-Arrow-Left.inline.svg";
import ArrowRight from "../../svg/Icon-Arrow-Right.inline.svg";
import Logo from "../../svg/Logo_Antarktyka.inline.svg";
import LanguageSelection from "../../components/LanguageSelection";

const query = graphql`
  {
    allFile(
      filter: { relativeDirectory: { regex: "/galleries/heroBg/" } }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        relativeDirectory
        childImageSharp {
          id
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
    }
  }
`;

const HeroSection = ({ content, menu, active, id, language, setLanguage }) => {
  const navLinks = [...Object.values(menu.nav)];
  const cont = [...Object.values(content)];
  // const bg = cont[0].img.src[0];

  const [scrollPosition, setScrollPosition] = useState(0);
  const bgImagesData = useStaticQuery(query);
  const [intervalId, setInterval] = useState();
  const [mobile, setMobile] = useState(true);

  useEffect(() => {
    const html = document.querySelector("html");
    const cw = html.clientWidth;
    if (cw > 992) {
      setMobile(false);
    }
  }, []);

  useEffect(() => {
    const html = document.querySelector("html");
    const cw = html.clientWidth;
    if (cw > 992) {
      setMobile(false);
      clearInterval(intervalId);
      setInterval(setTimeout(() => scroll(1), 6000));
    } else setMobile(true);
  }, [scrollPosition]);

  const scroll = async (a) => {
    setScrollPosition((prevState) => {
      if (prevState + a > bgImagesData.allFile.nodes.length - 1) return 0;
      if (prevState + a < 0) return bgImagesData.allFile.nodes.length - 1;
      return prevState + a;
    });
  };

  return (
    <>
      <div className={style.container} id={id}>
        {mobile
          ? bgImagesData.allFile.nodes.map((img, id) => (
              <div key={id} className={`${style.background} ${style.active}`}>
                <GatsbyImage
                  image={getImage(bgImagesData.allFile.nodes[0])}
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                  }}
                  alt=""
                  imgStyle={{ objectFit: "cover" }}
                />
              </div>
            ))
          : bgImagesData.allFile.nodes.map((img, id) => (
              <div
                key={id}
                className={
                  scrollPosition === id
                    ? `${style.background} ${style.active}`
                    : style.background
                }
              >
                <GatsbyImage
                  image={getImage(img)}
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                  }}
                  alt=""
                  imgStyle={{ objectFit: "cover" }}
                />
              </div>
            ))}
        <div className={style.bigDot}></div>
        <div className={style.content}>
          <div className={style.top}>
            <StaticImage
              className={style.logo}
              src="../../images/logo.png"
              alt="construction expedition"
              width={196}
            />
            <div className={style.topNav}>
              {navLinks.map((link) => (
                <a
                  className={style.link}
                  href={`#${link.link}`}
                  key={link.link}
                >
                  <p>{link.text}</p>
                  <div
                    className={style.bar}
                    style={
                      active === link.link
                        ? { backgroundColor: "var(--red-color)" }
                        : {}
                    }
                  ></div>
                </a>
              ))}
              <LanguageSelection
                language={language}
                setLanguage={setLanguage}
              />
            </div>
          </div>
          <div className={style.bottom}>
            <div className={style.bottomNav}>
              <div className={style.arrows}>
                <div onClick={() => scroll(-1)}>
                  <ArrowLeft />
                </div>
                <div onClick={() => scroll(1)}>
                  <ArrowRight />
                </div>
              </div>
              <div className={style.dots}>
                {bgImagesData.allFile.nodes.map((dot, id) => (
                  <div
                    key={id}
                    className={
                      id === scrollPosition ? style.navDotActive : style.navDot
                    }
                    onClick={() => setScrollPosition(id)}
                  />
                ))}
              </div>
            </div>
            <div className={style.bottomText}>
              <div className={style.title}>
                <p>{cont[0].title[scrollPosition]}</p>
              </div>
              <div className={style.subtitle}>
                {cont[0].subtitle[scrollPosition]}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
