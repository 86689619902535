import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { useEffect, useState } from "react";
import * as style from "./PeopleSection.module.scss";

// import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import ArrowLeft from "../../svg/Icon-Arrow-Left.inline.svg";
import ArrowRight from "../../svg/Icon-Arrow-Right.inline.svg";

const query = graphql`
  {
    allFile(
      filter: { relativeDirectory: { eq: "people" } }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        relativeDirectory
        childImageSharp {
          id
          gatsbyImageData(layout: FIXED, placeholder: BLURRED, formats: WEBP)
        }
      }
    }
  }
`;

const PeopleSection = ({ content }) => {
  const [page, setPage] = useState(0);
  const [person, setPerson] = useState(content.people_content[page]);

  useEffect(() => {
    setPerson(content?.people_content[page]);
  }, [page, content]);

  const scroll = async (a) => {
    setPage((prevState) => {
      if (prevState + a > content.people_content.length - 1) return 0;
      if (prevState + a < 0) return content.people_content.length - 1;
      return prevState + a;
    });
  };

  const imageData = useStaticQuery(query).allFile.nodes;

  return (
    <div className={style.container}>
      <div className={style.title}>
        <h2>{content.title.text}</h2>
      </div>
      <div className={style.contentContainer}>
        <div className={style.photoContainer}>
          <div className={style.photo}>
            <div className={style.shade}></div>
            <GatsbyImage
              image={getImage(imageData[page])}
              style={{
                width: "100%",
                height: "100%",
                overflow: "hidden"

              }}
              imgStyle={{ objectFit: "contain"}}
              alt=""
            />
            <div className={style.redDot}></div>
          </div>
          {/* duplicate navigation for mobile layout */}
          <div className={style.navigation}>
            <div className={style.pages}>
              <span>{page + 1}</span>
              <span className={style.bigger}>|</span>
              <span>{content.people_content.length}</span>
            </div>
            <div className={style.arrows}>
              <div onClick={() => scroll(-1)}>
                <ArrowLeft />
              </div>
              <div onClick={() => scroll(1)}>
                <ArrowRight />
              </div>
            </div>
          </div>
        </div>
        <div className={style.descryptionContainer}>
          <div className={style.descryption}>
            <p>{person.quote}</p>
            <div className={style.divider}></div>
            <span>{person.name}</span>
          </div>

          {/* navigation for PC layout */}
          <div className={style.navigation}>
            <div className={style.arrows}>
              <div onClick={() => scroll(-1)}>
                <ArrowLeft />
              </div>
              <div onClick={() => scroll(1)}>
                <ArrowRight />
              </div>
            </div>
            <div className={style.pages}>
              <span>{page + 1}</span>
              <span className={style.bigger}>|</span>
              <span>{content.people_content.length}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PeopleSection;
